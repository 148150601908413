<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
       خريطة موقع دايناميك    
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 60%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="اسم الخريطة"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="SitMapName"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.sitemap_name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Link"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <label>
                {{ `https://www.ar.lpcentre.com.com/sitemaps/${addCourseForm.sitemap_name}.xml` }}
              </label>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <label class="flex">المدن </label>
          </b-col>
          <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.cities"
              label="saveas"
              class="mb-2"
            >
              <b-form-radio value="1">
                نعم
              </b-form-radio>
              <b-form-radio value="0">
                لا
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col md="2">
            <label>الفئات</label>
          </b-col>
          <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.categories"
              label="saveas"
              class="mb-2"
            >
              <b-form-radio value="1">
                نعم
              </b-form-radio>
              <b-form-radio value="0">
                لا
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col md="2">
            <label> الروابط الأساسية</label>
          </b-col>
          <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.main_urls"
              label="saveas"
              class="mb-2"
            >
              <b-form-radio value="1">
                نعم
              </b-form-radio>
              <b-form-radio value="0">
                لا
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col md="2">
            <label>الدورات </label>
          </b-col>
          <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.courses"
              label="saveas"
              class="mb-2"
            >
              <b-form-radio value="1">
                نعم
              </b-form-radio>
              <b-form-radio value="0">
                لا
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col md="2">
            <label>المقالات</label>
          </b-col>
          <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.posts"
              label="saveas"
              class="mb-2"
            >
              <b-form-radio value="1">
                نعم
              </b-form-radio>
              <b-form-radio value="0">
                لا
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col md="2">
            <label>الجدولة</label>
          </b-col>
          <b-col md="10">
            <b-form-radio-group
              v-model="addCourseForm.schedules"
              label="saveas"
              class="mb-2"
            >
              <b-form-radio value="1">
                نعم
              </b-form-radio>
              <b-form-radio value="0">
                لا
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col md="12">
            <b-row v-if="addCourseForm.schedules==1">
              <b-col
                md="6"
                xl="6"
              >
                <b-form-group
                  label="تاريخ البدء"
                  label-for="blog-edit-course"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Date"
                    rules="required"
                  >

                    <flat-pickr
                      v-model="addCourseForm.start_date"
                      class="form-control"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <b-form-group
                  label="تاريخ الانتهاء"
                  label-for="blog-edit-course"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="end Date"
                    rules="required"
                  >

                    <flat-pickr

                      v-model="addCourseForm.end_date"
                      class="form-control"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
             توليد 
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BFormRadio,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

import Ripple from 'vue-ripple-directive'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    const form = ref({
      main_urls: 0,
      sitemap_name: '',
      cities: 0,
      categories: 0,
      courses: 0,
      posts: 0,
      schedules: 0,
      start_date: '',
      end_date: '',
    })
    const addCourseForm = reactive(form)
    // store.dispatch("setting/GetHomeSection").then((response) => {
    //   console.log("GetHomeSection", response);
    //   form.value = response.data;
    // });

    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('main_urls', addCourseForm.value.main_urls)
          formData.append('sitemap_name', addCourseForm.value.sitemap_name)
          formData.append('cities', addCourseForm.value.cities)
          formData.append('categories', addCourseForm.value.categories)
          formData.append('courses', addCourseForm.value.courses)
          formData.append('posts', addCourseForm.value.posts)
          formData.append('schedules', addCourseForm.value.schedules)
          if (addCourseForm.value.schedules == 1) {
            formData.append('start_date', addCourseForm.value.start_date)
            formData.append('end_date', addCourseForm.value.end_date)
          }

          store
            .dispatch('setting/GenerateDynaimcMap', formData)
            .then(response => {
              Vue.swal({
                title: ' Updated ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    return {
      addCourseForm,

      addCourseFormvalidate,

      form,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    BForm,
    ValidationProvider,
    flatPickr,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
